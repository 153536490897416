.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: white;
}

.header {
  margin-bottom: 2rem;
}

.logo {
  width: 400px;
  height: auto;
}

.mainContent {
  text-align: center;
  color: white;
  margin-bottom: 2rem;
}

.mainContent h3 {
  font-size: 1.5rem;
  color: white;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 8px;
  background-color: #fee500;
  color: #000;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.social-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.social-button img {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 320px;
  margin: 2rem auto;
}

.input {
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.loginButton {
  padding: 0.8rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.loginButton:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .main-content h3 {
    font-size: 1.2rem;
  }

  .social-button {
    max-width: 250px;
  }
}
