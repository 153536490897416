/* src/components/common/Sidebar/sidebar.module.css */
.sidebar {
  width: 200px;
  background-color: #f8f9fa;
  padding: 20px;
  height: 100vh;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 20px;
}

.sidebar a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.sidebar a:hover {
  color: #007bff;
}
