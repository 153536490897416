.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 20px;
  background-color: #e3f2fd; /* 더 밝은 파란색 배경 */
}

.tabBar {
  display: flex;
  margin-bottom: 20px;
}

.tab {
  padding: 12px 24px;
  cursor: pointer;
  background-color: #90a4ae;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  margin-right: 10px;
  transition: background-color 0.2s ease;
}

.tab:hover {
  background-color: #607d8b;
}

.active {
  background-color: #1e88e5;
  color: white;
}

.reportCard {
  background-color: #ffffff; /* 따뜻한 흰색 배경 */
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin-bottom: 16px;
  transition: transform 0.3s ease;
}

.reportCard:hover {
  transform: scale(1.02);
}

.executed {
  border-left: 6px solid #66bb6a;
  opacity: 0.9;
}

.title {
  margin: 0;
  font-size: 20px;
  color: #2e7d32; /* 진한 녹색으로 제목 강조 */
}

.reportDetails p {
  margin: 6px 0;
  font-size: 15px;
  color: #444;
}

.tagContainer {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  flex-wrap: wrap;
}

.dangerTag {
  color: #d32f2f;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 8px;
  background-color: #ffebee;
  border-radius: 4px;
}

.warningTag {
  color: #ff6d00;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 8px;
  background-color: #fff3e0;
  border-radius: 4px;
}

.safeTag {
  color: #2e7d32;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 8px;
  background-color: #e8f5e9;
  border-radius: 4px;
}

.pendingTag {
  color: #0277bd;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 8px;
  background-color: #e1f5fe;
  border-radius: 4px;
}

.processedTag {
  color: #6a1b9a;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 8px;
  background-color: #f3e5f5;
  border-radius: 4px;
}

.urgentTag {
  color: #c62828;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 8px;
  background-color: #ffcdd2;
  border-radius: 4px;
  animation: blink 2s infinite;
}
.noUnderline {
  text-decoration: none;
  color: inherit;
}
