.footer {
  background: linear-gradient(to bottom, #1a1a1a, #2d2d2d);
  color: white;
  padding: 4rem 0 2rem;
  position: relative;
  overflow: hidden;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.mainSection {
  text-align: center;
  margin-bottom: 4rem;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #60a5fa, #a78bfa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 1.2rem;
  color: #a0aec0;
  margin-bottom: 2rem;
}

.newsletter {
  display: flex;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
}

.emailInput {
  flex: 1;
  padding: 0.8rem 1.2rem;
  border-radius: 9999px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

.subscribeButton {
  padding: 0.8rem 2rem;
  border-radius: 9999px;
  border: none;
  background: linear-gradient(to right, #60a5fa, #a78bfa);
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.subscribeButton:hover {
  transform: translateY(-2px);
}

.linksContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 4rem;
}

.linkColumn h3 {
  color: #60a5fa;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.linkColumn a {
  display: block;
  color: #a0aec0;
  text-decoration: none;
  margin-bottom: 0.8rem;
  transition: color 0.3s ease;
}

.linkColumn a:hover {
  color: white;
}

.bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social {
  display: flex;
  gap: 1.5rem;
  font-size: 1.5rem;
}

.social svg {
  cursor: pointer;
  transition: color 0.3s ease;
}

.social svg:hover {
  color: #60a5fa;
}

.copyright {
  color: #a0aec0;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .linksContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .bottom {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .linksContainer {
    grid-template-columns: 1fr;
  }

  .newsletter {
    flex-direction: column;
  }

  .title {
    font-size: 2rem;
  }
}
