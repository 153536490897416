.header {
  width: 90%;
  background-color: #ffffff;
  position: relative;
  z-index: 10;
  margin: 0 auto;
}

.container {
  padding: 1rem;
  width: 90%;
  background-color: #ffffff00;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  width: 8rem;
}

.mobileActions {
  display: none;
}

.nav {
  display: block;
}

.navList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.navItem {
  display: flex;
  align-items: center;
  color: #4b5563;
  padding: 0.75rem 1rem;
  text-decoration: none;
  background: none;
  border: none;
  transition: background-color 0.2s;
}

.navItem:hover {
  background-color: #e9ecef;
}

.icon {
  margin-right: 0.5rem;
}

.userName {
  display: block;
  font-weight: 500;
  margin-left: 1rem;
}

.desktopOnly {
  display: block;
}

.desktopOnly button {
  background: none;
  border: none;
}

@media (max-width: 768px) {
  .mobileActions {
    display: flex;
    align-items: center;
  }

  .mobileButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    margin-left: 0.5rem;
    font-size: 1.5rem; /* 이모지 크기 조절 */
  }

  .nav {
    display: none;
  }

  .navList {
    flex-direction: column;
    align-items: flex-start;
  }

  .navItem {
    width: 100%;
  }

  .userName {
    display: none;
  }

  .showMobileMenu {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #f8f9fa;
    border-top: 1px solid #e9ecef;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 1024px) {
  .logo {
    width: 10rem;
  }

  .navList {
    gap: 1rem;
  }
}
