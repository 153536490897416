.container {
  width: 100%;
  background: white;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 4rem 6%;
  position: relative;
  overflow: hidden;
}

.contentWrapper {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.textWrapper {
  flex: 1;
  max-width: 600px;
  z-index: 10;
}
.titleContainer {
  display: flex;
}
.mainTitle {
  font-size: 3.5rem;
  font-weight: 800;
  color: black;
  margin-left: 1rem;
  margin-bottom: 2rem;
}

.text {
  font-size: 1.5rem;
  color: black;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.subText {
  font-size: 1.25rem;
  color: black;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.boldText {
  font-size: 1.75rem;
  font-weight: 700;
  color: black;
  margin-top: 2rem;
}

.imageWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
}
.scrollTop {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background: white;
  color: #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
}

.showScroll {
  opacity: 1;
  visibility: visible;
}

.scrollTop:hover {
  transform: translateY(-5px);
  background: #000000;
  color: #ffffff;
}

@media (max-width: 768px) {
  .scrollTop {
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1024px) {
  .mainTitle {
    font-size: 3rem;
  }

  .text {
    font-size: 1.25rem;
  }

  .boldText {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .contentWrapper {
    flex-direction: column;
    text-align: center;
  }

  .mainTitle {
    font-size: 2.5rem;
  }

  .section {
    padding: 3rem 4%;
  }
}

@media (max-width: 480px) {
  .mainTitle {
    font-size: 2rem;
  }

  .text {
    font-size: 1.1rem;
  }

  .subText {
    font-size: 1rem;
  }

  .boldText {
    font-size: 1.25rem;
  }
}
