/* src/pages/admin/reportDetail.module.css */
.container {
  display: flex;
  padding: 20px;
  background-color: #f0f4f8; /* Light background for contrast */
}

.mainContent {
  flex: 2;
  padding-right: 20px;
}

.imageList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.imageItem {
  width: 300px;
  height: 300px;
}

.image {
  width: 100%;
  height: auto;
  max-height: 200px;
}

.detailPanel {
  flex: 1;
  margin-left: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.detailImage {
  width: 100%;
}

.actions {
  margin-top: 20px;
}

button {
  margin-right: 10px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}
/* src/pages/admin/reportDetail.module.css */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

button {
  margin-top: 10px;
}
