.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: black;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: Arial, sans-serif;
}

.title {
  font-size: 120px;
  color: #ffffff;
  margin-bottom: 0;
}

.subtitle {
  font-size: 24px;
  color: #fafdff;
  margin-top: 0;
}

.description {
  font-size: 18px;
  color: #ffffff;
  max-width: 500px;
  margin: 20px 0;
}

.homeButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.homeButton:hover {
  background-color: #0056b3;
}
